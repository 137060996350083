exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-auditable-ddm-js": () => import("./../../../src/pages/auditable-ddm.js" /* webpackChunkName: "component---src-pages-auditable-ddm-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-creative-jsx": () => import("./../../../src/pages/creative.jsx" /* webpackChunkName: "component---src-pages-creative-jsx" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-neuroscience-jsx": () => import("./../../../src/pages/neuroscience.jsx" /* webpackChunkName: "component---src-pages-neuroscience-jsx" */),
  "component---src-pages-neuroscience-mdx-frontmatter-slug-js-content-file-path-src-posts-context-processing-index-mdx": () => import("./../../../src/pages/neuroscience/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/context-processing/index.mdx" /* webpackChunkName: "component---src-pages-neuroscience-mdx-frontmatter-slug-js-content-file-path-src-posts-context-processing-index-mdx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-scrum-planner-js": () => import("./../../../src/pages/scrum-planner.js" /* webpackChunkName: "component---src-pages-scrum-planner-js" */),
  "component---src-pages-software-jsx": () => import("./../../../src/pages/software.jsx" /* webpackChunkName: "component---src-pages-software-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-volunteer-computing-js": () => import("./../../../src/pages/volunteer-computing.js" /* webpackChunkName: "component---src-pages-volunteer-computing-js" */),
  "component---src-pages-webdev-jsx": () => import("./../../../src/pages/webdev.jsx" /* webpackChunkName: "component---src-pages-webdev-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

